import axios from "axios";
import useClipboard from 'vue-clipboard3'
const {toClipboard} = useClipboard();
/**
 * 日志埋点方法
 * @param {*} moduleid  模块id
 * @param {*} action  1.查看 2.触发
 */
export let moduleEvent = (moduleid,action) => {
	//事件埋点
	 axios.put("/api/v1.0/record", {
		module: moduleid,
		action: action
	 }).then(res => {
		console.log(res);
	 }).catch(err => {
		console.log(err);
	 });
} 

/**
 * 复制数据
 * @param {*} val 
 */
export let clipboard = async (val) =>{
	try{
		await toClipboard(val);
	}catch(e){
		console.error(e);
	}
}
