var texttemplet ="我是做软件开发的？"+
				"你写软件？那你写个微信出来呗～" + 
				"你写网站？写个淘宝出来看看啊！" + 
				"你学计算机的？那买些电子元件你能做出个电脑的吧？" + 
				"你会写汉字？咋不写个红楼梦出来" + 
				"别给人打工了，你写点软件搞个专利，出来开公司呗～" + 
				"整天盯着电脑，辐射大，死得早吧？" + 
				"你们理工科的，情商很低吧？" + 
				"嗯，听你说话看得出来你情商很高！" + 
				"帮我抢个火车票／抢个单／抢个红包／抢个小米呗！" + 
				"帮我下个软件／装个软件／装机／清理内存吧！" + 
				"我密码忘了，你帮我找回来。" + 
				"你知道我开机密码/邮箱密码/QQ密码是多少吗？" + 
				"我邮箱被盗了，你帮我盗回来。" + 
				"我不小心删了xxxx，你帮我恢复回来吧。" + 
				"你帮我看看这个手机／电脑，出什么毛病了？" + 
				"手机进水／摔了，你能帮我修修吗？" + 
				"听说那些程序猿，钱多话少死的早。" + 
				"加班加到十点半，女朋友都存电脑。" + 
				"机子蓝屏又死机，学电脑的来修好。" + 
				"折腾半天弄不对，大学都学啥去了。" + 
				"我想要个小网站，明早就给我写好。" + 
				"手机应用挺赚钱，周末上线早不早？" + 
				"你这敲的啥玩意，一行能值几个钱。" + 
				"账号密码帮我盗，游戏外挂少不了。" + 
				"工资不如拿期权，不为钱财为理想。" + 
				"到底是吃青春饭，不如跟我卖保险。卖！保！险！" + 
				"三姑六婆：你在清华学计算机啊。" + 
				"我：对啊。" + 
				"三姑六婆：和北大青鸟比哪个更好？" + 
				"一脸懵逼！" + 
				"其实想说应该都不如蓝翔吧，还是忍住了" + 
				"公司妹子一副（老娘就是不给你钱还要找你帮忙）的样子：你就帮我改一下嘛，很简单的。" + 
				"就从静态页面改成动态。一天能好不" + 
				"能骂人吗？不能？那没什么好说的！";

export let generate = (size) => {
	var i = 0;
	let text = new Array(size)
    .fill()
    .map(() => {
	var charStr = texttemplet.charAt(i);
		i++;
	return charStr
    });
    return text;
}