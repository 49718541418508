/**
 * number类型输入框,校验规则
 * @param {*} value 
 */
let checkresult = {};
export let numberule = (size,max) => {
	checkresult.success=true;
    if(typeof size !="number" || size=="" || 1 > size || size > max){
		checkresult.success=false;
		checkresult.info="不要淘气( ^___^ )，请输入1-"+max+"正整数";
	}
	return checkresult;
}

export let textrule = (length,max) => {
	checkresult.success=true;
	if(1 > length){
		checkresult.success=false;
		checkresult.info="输入内容,才能进行处理哦( ^___^ )";
	}
    if(length > max){
		checkresult.success=false;
		checkresult.info="文本超长了,支持"+max+"内字符处理";
	}
	return checkresult;
}
