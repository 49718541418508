<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >文本在线生成</h2>
		<el-row style="margin-top: 4%;">
			<el-col :sm="11" style="margin: 0 auto; text-align: center;">
				<el-form :model="docData">
					<el-form-item label="生成数量">
							<el-col :sm = "20">
								<el-input v-model.number="docData.size" placeholder="请输入1-2000范围整数"></el-input>
							</el-col>
					</el-form-item>
					
					<el-form-item label="">
						<el-col :sm = "4"></el-col>
						<el-input readonly  type="textarea" :rows="docData.rows" resize='none'  v-model="docData.text"></el-input>
					</el-form-item>
					
					<div style="margin: 0 auto; text-align: center;">
						<el-button id="1001" type="primary" @click="generate()">生成</el-button>
						<el-button  @click="copy(docData.text)" type="primary" plain>复制</el-button>
					</div>
				</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {generate as docGenerate} from './doc'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

    let docData = ref({
		moduleid:1005,
        size: 100,
        rows: 10,
        name: ""
    })
	
	moduleaction(docData.value.moduleid,1);
    let generate = () => {
		//校验代码
		var validresult = checknumber(docData.value.size,2000);
		if(!validresult.success){
			docData.value.text = validresult.info;
			return;
		}
		docData.value.text = docGenerate(parseInt(docData.value.size)).join("");
		//模块事件 1.预览 2.使用
		moduleaction(docData.value.moduleid,2);
    }
//进入页面，触发数据生成逻辑
generate();	
</script>

<style>

</style>